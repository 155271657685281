<template>
  <div class="flex items-center justify-between">
    <div>
      <h2 class="h4 font-bold"><slot name="header">your header text here</slot></h2>
      <div class="text-foreground-2">
        <slot name="subheader">your subheader text here</slot>
      </div>
    </div>
    <div class="space-x-2 flex items-center justify-end">
      <slot name="actions">
        <FormButton size="lg" color="card">Secondary Action</FormButton>
        <FormButton size="lg">Primary Action</FormButton>
      </slot>
    </div>
  </div>
</template>
